import React, { Component } from 'react'
import Link from 'gatsby-link'
import Footer from '../components/Footer/Footer'
import Helmet from 'react-helmet'
import {ClockIcon, ContactIcon, EmailIcon, LocationIcon} from "../components/icons/index";
import Layout from '../layouts';

const iconColor = "#4B53BC"

export default class extends Component {

    render() {
        
        return (
            <Layout location={this.props.location}>
                <Helmet>
                    <title>Contactez le Psy-Scan Institute | 058 201 06 11</title>
                    <meta name="description" content="Nos consultations se déroulent du Lundi au Vendredi de 9h à 12h et 13h à 17h. Vous pouvez nous appelez ou réserver votre consultation en ligne."/>
                </Helmet>
                <div>
                    <div style={{maxWidth: 1170, padding: '60px 16px', margin: 'auto'}}>
                        <div style={{textAlign:'center', fontSize:'1.3rem', marginBottom: 60}}>
                            <div style={{color:'#000'}}>
                                <h1>Nous Joindre</h1>
                            </div>
                            <div style={{marginBottom: 24}}>
                                <div>
                                    <ClockIcon fill={iconColor} width="32" height="32"/>
                                </div>
                                <div>Lundi à Vendredi</div>
                                <div>9h - 12h et 13h - 17h</div>
                            </div>
                            <div style={{marginBottom: 24}}>
                                <a href="tel:+41582010611" style={{color:'inherit'}}>
                                    <div>
                                        <ContactIcon fill={iconColor} width="32" height="32"/>
                                    </div>
                                    <div>
                                        058 201 06 11
                                    </div>
                                </a>
                            </div>
                            <div style={{marginBottom: 24}}>
                                <a href="mailto:admin@psy-scan.ch" style={{color:'inherit'}}>
                                    <div>
                                        <EmailIcon fill={iconColor} width="32" height="32"/>
                                    </div>
                                    <div>
                                        admin@psy-scan.ch
                                    </div>
                                </a>
                            </div>
                            <div style={{marginBottom: 24}}>
                                <div>
                                    <LocationIcon fill={iconColor} width="32" height="32"/>
                                </div>
                                <a style={{color:'inherit'}} target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/PSI+:+Psy-Scan+Institute+S%C3%A0rl/@46.991943,6.928913,16z/data=!4m5!3m4!1s0x0:0xdc7be15bcbfa5718!8m2!3d46.9919431!4d6.9289134?hl=fr-fr">
                                    <div>Grand-Rue 1A</div>
                                    <div>1er Étage</div>
                                    <div>2000 Neuchâtel</div>
                                </a>
                            </div>

                            <div style={{marginBottom: 24}}>
                                <div>
                                    <LocationIcon fill={iconColor} width="32" height="32"/>
                                </div>
                                <a style={{color:'inherit'}} target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/PSI+:+Psy-Scan+Institute+S%C3%A0rl/@46.991943,6.928913,16z/data=!4m5!3m4!1s0x0:0xdc7be15bcbfa5718!8m2!3d46.9919431!4d6.9289134?hl=fr-fr">
                                    <div>Rue de la Maladière 20</div>
                                    <div>Rez-de-Chaussée : Porte 1</div>
                                    <div>Code de l’entrée : 2020</div>
                                    <div>2000 Neuchâtel</div>
                                </a>
                            </div>
                        </div>
                        <div style={{borderBottom:'1px solid #e6e6e6', marginBottom:48}} />
                        <div style={{textAlign:'center', fontSize:'1.2rem', maxWidth: 767, margin:'auto', marginBottom: 48}}>
                            <h2 style={{color:"#000"}}>Prendre Rendez-Vous</h2>
                            <p>
                                Vous pouvez prendre un rendez-vous en remplissant notre <Link to="/reservation">formulaire en ligne</Link> (recommandé), par telephone ou par courriel.
                            </p>
                        </div>
                        <div style={{textAlign:'center', fontSize:'1.2rem', maxWidth: 767, margin:'auto', marginBottom: 48}}>
                            <h2 style={{color:"#000"}}>Annuler un Rendez-Vous</h2>
                            <p>
                                Si vous annulez votre premier rendez-vous avec un préavis de moins de 48 heures, ou si vous ne vous présentez pas, vous serez facturé le montant total de la consultation.
                            </p>
                        </div>
                        <div style={{textAlign:'center', fontSize:'1.2rem', maxWidth: 767, margin:'auto', marginBottom: 48}}>
                            <h2 style={{color:"#000"}}>Remboursement</h2>
                            <p>
                                Toutes les thérapies au Psy-Scan Institute sont remboursées par votre assurance maladie de base. Pour plus d'information veuillez prendre contact avec le secrétariat.
                            </p>
                        </div>
                    </div>
                </div>
                <Footer/>

            </Layout>
        )
    }
}

